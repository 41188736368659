import React, { useEffect, useState } from 'react';
import {
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Table,
} from 'semantic-ui-react';
import { ReactComponent as ArrowIcon } from "../../icons/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from "../../views/Tasks/reducer";
import { fetchTasks } from "../../views/Tasks/actions";
import "./style.css";
import {useNavigate} from "react-router-dom";

const CalendarTable = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const tasks = useSelector(state => getTasks(state.tasks) || []); // Безопасный доступ к задачам
    const dispatch = useDispatch();
    const token = useSelector(state => state.userLogin?.userToken?.token || '');

    const handlePrevMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    const getMonthName = (month) => {
        const months = [
            "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];
        return months[month];
    };

    const generateCalendar = () => {
        const navigate = useNavigate();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const daysInMonth = lastDayOfMonth.getDate();

        const rows = [];
        let cells = [];
        const dayOfWeek = firstDayOfMonth.getDay() || 7; // Начало недели - понедельник

        // Добавляем пустые ячейки перед первым днем месяца
        for (let i = 1; i < dayOfWeek; i++) {
            cells.push(<TableCell key={`empty-before-${i}`} className="calendar-table-cell-day"></TableCell>);
        }

        for (let day = 1; day <= daysInMonth; day++) {
            const currentDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

            // Задачи на конкретный день
            const dayTasks = tasks.filter(task => {
                if (!task.order_create_date?.date) return false;

                const taskDate = new Date(task.order_create_date.date);
                return (
                    taskDate.getFullYear() === currentDayDate.getFullYear() &&
                    taskDate.getMonth() === currentDayDate.getMonth() &&
                    taskDate.getDate() === currentDayDate.getDate()
                );
            });

            cells.push(
                <TableCell key={`day-${day}`} className="calendar-table-cell">
                    <div className="day-number">{day}</div>
                    {dayTasks.map((task, index) => (
                        <div
                            key={task.id_order}
                            className="task-id"
                            style={{
                                marginTop: index === 0 ? "10px" : "0",
                            }}
                        >
                            <a onClick={() => navigate('/task/' + task.id)}>
                            №{task.id_order}
                            </a>
                        </div>
                    ))}
                </TableCell>
            );

            // Добавляем строку, если конец недели или конец месяца
            if ((day + dayOfWeek - 1) % 7 === 0 || day === daysInMonth) {
                // Если последний день месяца, добавляем оставшиеся пустые ячейки
                if (day === daysInMonth) {
                    const remainingCells = 7 - ((day + dayOfWeek - 1) % 7 || 7);
                    for (let i = 1; i <= remainingCells; i++) {
                        cells.push(
                            <TableCell
                                key={`empty-after-${i}`}
                                className="calendar-table-cell-day"
                                style={{ backgroundColor: "#F6F6F6" }}
                            ></TableCell>
                        );
                    }
                }

                rows.push(<TableRow key={`row-${rows.length}`}>{cells}</TableRow>);
                cells = [];
            }
        }

        return rows;
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchTasks(token));
        }
    }, [dispatch, token]);

    return (
        <>
            <div className="calendar-controls">
                <ArrowIcon className="calendar-arrow" onClick={handlePrevMonth} />
                <span className="calendar-month-display">
                    {getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}
        </span>
                <ArrowIcon className="calendar-arrow" style={{ transform: "rotate(180deg)" }} onClick={handleNextMonth} />
            </div>
            <span className="calendar-month-label">Месяц</span>
            <Table size="small" celled className="calendar-table">
                <TableBody>
                    <TableRow>
                        {['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'].map((day, index) => (
                            <TableHeaderCell
                                className={`calendar-table-cell-day-week ${
                                    index === 0 ? 'first-day' : index === 6 ? 'last-day' : ''
                                }`}
                                key={day}
                            >
                                {day}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                    {generateCalendar().map((row, index) => (
                        <TableRow key={`row-${index}`} className="calendar-table-row">
                            {row.props.children}
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </>
    );
};

export default CalendarTable;