import React, {useEffect, useState} from 'react';
import {Radio} from "semantic-ui-react";
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import "./style.css"
import CalendarTable from "../../components/CalendarTable";
import {useSelector} from "react-redux";
import {getTasks} from "../Tasks/reducer";
import {fetchTasks} from "../Tasks/actions";

const EmploymentCalendar = props => {
    const {navigate, dispatch} = props
    const token = useSelector(state => state.userLogin.userToken.token);
    const allTasks = useSelector(state => getTasks(state.tasks));
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        // Фильтруем задачи со статусом "new"
        const filteredTasks = Object.values(allTasks).filter((item) => item.task_status === "new");

        // Создаем JSX для отфильтрованных задач
        const arrayTask = filteredTasks.map((item, index) => (
            <div className="profile-task" key={item.id_order}>
                <div
                    className="profile-task-number"
                    style={{
                        borderRadius: "10px 0 0 0",
                        backgroundColor: index % 2 === 0 ? "#F6FCFF" : "#EBF6FF",
                    }}
                >
                    №{item.id_order}
                </div>
                <div className="employment-profile-task-text">{item.product_name}</div>
                <div className="profile-task-company">{item.customer_address.company}</div>
            </div>
        ));
        // Устанавливаем задачи
        setTasks(arrayTask);
    }, [allTasks]);

    useEffect(() => {
        // Загружаем задачи из API при изменении токена
        if (token) {
            dispatch(fetchTasks(token));
        }
    }, [token, dispatch]);


    const [selectedPlanned, setSelectedPlanned] = useState(null)

    function handleChange(e, {value}) {
        setSelectedPlanned(value)
    }
    return (
        <>
            <div className="service-payment-container">
                <div className='service-payment-header'>
                    <BackIcon className='service-payment-header-img' onClick={() => navigate('/profile')}/>
                    <div className='title-text'>Календарь занятости</div>
                </div>

                <div className="checkboxes-box" style={{marginBottom:"10px"}}>
                    <Radio
                        label='Запланированные'
                        name='radioGroup'
                        value='planned'
                        checked={selectedPlanned === 'planned'}
                        onChange={handleChange}
                        className={'checkbox-box'}
                    />
                    <Radio
                        label='Завершенные'
                        name='radioGroup'
                        value='done'
                        checked={selectedPlanned === 'done'}
                        onChange={handleChange}
                        className={'checkbox-box'}
                    />
                </div>
            </div>
            <CalendarTable/>
            <div className='profile-tasks' style={{marginLeft:"2.5%"}}>
                <span className="title-text">Незапланированные задачи</span>
                {tasks.length > 0 ?
                    <div className='profile-tasks-container'>
                        {tasks}
                    </div> : <div style={{marginLeft: "5px"}}>Нет задач</div>}
            </div>
        </>
    )
}

export default EmploymentCalendar;