import React, {useState} from 'react';
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import { Radio, Rating } from 'semantic-ui-react'

import './style.css';

const ReviewCard = (props) => {
    return (
        <div className='review-card'>
            <div className="review-card-header">№12345</div>
            <div className='review-card-content'>
                <div className="review-header">
                    <span className='review-name'>Аудит выращивания молодняка</span>
                    <span className="review-date">12.12.2023</span>
                    <Rating
                        className="review-rating"
                        maxRating={5}
                        clearable
                        size='mini'
                    />
                </div>

                <div className="review-description">
                    <div className="review-row-container">
                        <span><b>Клиент: </b></span>
                        <span className="review-text">ООО “Ромашка”</span>
                        <div className="review-client-rating">
                            <span>Рейтинг клиента</span>
                            <Rating
                                className="client-rating"
                                maxRating={5}
                                clearable
                                size='mini'
                            />
                        </div>
                    </div>

                    <div className="review-row-container">
                        <span><b>Плюсы: </b></span>
                        <span className="review-text">Провели аудит, замечательный специалист!</span>
                    </div>

                    <div className="review-row-container">
                        <span><b>Минусы: </b></span>
                        <span className="review-text">Провели аудит, ужасный специалист!</span>
                    </div>

                    <div className="review-row-container">
                        <span><b>Отзыв: </b></span>
                        <span className="review-text">Провели аудит, замечательный специалист. Профессионал своего дела. Помог всем моим коровам, животным, ферме. Самый крутой!!!</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ReviewsList(props) {
    const {} = props;
    return (
        <div>
            <ReviewCard
                // key={element.id}
                // forum={element}
                // showLoader
                // showDate={showDate}
                // {...props}
            />
        </div>
    );
}

export default function MyReviews(props) {

    const {navigate, dispatch} = props

    const [selectedReviews, setSelectedReviews] = useState(null)

    function handleChange(e, {value}) {
        setSelectedReviews(value)
    }
    return (
        <>
            <div className="service-payment-container">
                <div className='service-payment-header'>
                    <BackIcon className='service-payment-header-img' onClick={() => navigate('/profile')}/>
                    <div className='title-text'>Мои отзывы</div>
                </div>

                <div className="checkboxes-box">
                    <Radio
                        label='От клиента'
                        name='radioGroup'
                        value='client'
                        checked={selectedReviews === 'client'}
                        onChange={handleChange}
                        className={'checkbox-box'}
                    />
                    <Radio
                        label='От старшего специалиста'
                        name='radioGroup'
                        value='specialist'
                        checked={selectedReviews === 'specialist'}
                        onChange={handleChange}
                        className={'checkbox-box'}
                    />
                </div>
                <ReviewsList/>
            </div>
        </>
    )
}