import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const DoubleLineChart = (props) => {
    const {totalTasks} = props;
    console.log(Object.values(totalTasks))
    console.log(totalTasks)
    for(let i = 0; i < 12; i++){
        if(i < 9) {
            totalTasks[`2024-0${i+1}`] = totalTasks[`2024-0${i+1}`] ? totalTasks[`2024-0${i+1}`] : {earned_sum: 0, total_in_work: 0, total_closed: 0}
        } else {
            totalTasks[`2024-${i+1}`] = totalTasks[`2024-${i+1}`] ? totalTasks[`2024-${i+1}`] : {earned_sum: 0, total_in_work: 0, total_closed: 0}
        }
    }
    const dataGraph = Object.values(totalTasks).map((element, index) => {
        return element.total_closed
    })
    console.log(dataGraph)

    // Данные для графика
    const data = {
        labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        datasets: [
            {
                label: 'Всего задач',
                data: dataGraph,
                borderColor: '#0075F5',
                backgroundColor: '#6DCBFF',
                fill: true,
                pointRadius: 3,
            },
        ],
    };

    // Опции графика
    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Месяц',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Значения',
                },
                min: 0,
                max: 20,
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <Line data={data} options={options} />
    );
};

export default DoubleLineChart;
