import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Divider, Form, Icon, Image, Input, Label, Loader, Message, Modal,} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';

import {isAuthVisible} from '../NavTopBar/reducer';
import {closeAuth} from '../NavTopBar/actions';
import {
    AUTH_SCREEN_AUTH_ENTER_OTP,
    AUTH_SCREEN_MAIN, AUTH_SCREEN_RESET_PWD_ENTER_DATA, AUTH_SCREEN_RESET_PWD_ENTER_OTP, AUTH_SCREEN_SIGNUP,
    authByOTP,
    authGetOTP, clearEmail, clearPhoneNumber,
    fetchToken, resetPasswordGetOTP,
    setAuthScreen,
    setEmail, setPhone, signup
} from './actions';
import './UserLogin.css';
import '../AddressCard/styles.css'

import userLogin, {getAuthFetching, getAuthMode, getData, getUserFetching, getUserLoginError} from './reducer';
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields} from "../AddressCard/actions";
import {Link} from "react-router-dom";
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import _ from "lodash";


import {EmailSuggestions, FioSuggestions, PartySuggestions} from 'react-dadata';

import {toastr} from "react-redux-toastr";
import config from "../../config/config";
import {DADATA_API_KEY} from "../../config/secrets";
import {value} from "lodash/seq";
import {isOnline} from "../WindowDimensions";
import {isMobile} from "react-device-detect";
import {useMixpanel} from "react-mixpanel-browser";


export const cleanMessage = (text) => text? text.replace(/<\/?[^>]+(>|$)/g, "") : "";

export default function UserLogin (props) {

    const {userData } = props;
    const dispatch = useDispatch()
    const mixpanel = useMixpanel()
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getAuthFetching(state.userLogin));
    const data = useSelector((state) => getData(state.userLogin));
    //const isFetchingAuth = useSelector((state) => getAuthFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));
    const authMode = useSelector((state) => getAuthMode(state.userLogin));
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const defaultSettings = {
        phone: '',
        email: userData && userData.login ? userData.login : '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const [state, setState] = useState(defaultSettings);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [login, setLogin] = useState("")




    // console.log('UserLogin props & states Debug Mode', authMode, isFetching, phoneNumber);

    const languageString = "ru";
    const urlicon = 'https://e-feed.ru/wp-content/uploads/2024/11/logo_efeed.png'


    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: languageString === "en" ? element.country_en : element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;


    useEffect(() => {
        if (showModal) {
            setPhoneNumber('');
            setState({...state, email: ""});
        }
    }, [showModal]);

    useEffect(() => {

        // по умолчанию устанавливаем главное окно
        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));

        // если стран нет, то запрашиваем их
        if (!countries || !countries.countries) {
            dispatch(fetchCounties());
        }

    }, []);

    useEffect(() => {

        if (mixpanel)
            mixpanel.track('Login Dialog Open', { ...userData });

        //setState(defaultSettings);
        //setContent(null);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }

    function submitForm() {
        if (validateForm())
            dispatch(fetchToken(state.email, state.password, languageString));
        //setContent(null)
    }

    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    const handleChangeField = (fieldName, value) => {
        setPhoneNumber(value);
        //dispatch(authPhone(value))
    }



    const ConfirmCode = (props) => {
        // const { mixpanel } = props
        //  console.log(mixpanel)
        const [timeoutRepeat, setTimeoutRepeat] = useState(authMode.data.data.otp_get_timeout);
        const [timeoutWait, setTimeoutWait] = useState(authMode.data.data.otp_wait_timeout);

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {
            document.getElementById('input-number0').focus();
            let time = timeoutRepeat;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutRepeat(time);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
            let time = timeoutWait;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutWait(time);
                if (time === 0) {
                    clearInterval(intervalId);
                    toastr.error("Время сессии истекло, повторите попытку");
                    dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }, []);

        function handleKeyPress(e, index) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
                if (e.key === 'Backspace') {
                    if (inputs[index] === '') {
                        if (index > 0) {
                            document.getElementById(`input-number${index - 1}`).focus();
                        }
                    }
                } else {
                    if (inputs.length < 1) {
                        e.preventDefault();
                    }
                }
            } else {
                e.preventDefault();
            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
        }

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;

            if (value && index < inputs.length - 1) {
                document.getElementById(`input-number${index + 1}`).focus();
            } else if (!value && index > 0) {
                document.getElementById(`input-number${index - 1}`).focus();
            }

            setInputs(newInputs);

            const enteredCode = newInputs.join("");
            if (enteredCode.length === 4) {
                dispatch(authByOTP(languageString, config.APP_CODE, phoneNumber, authMode.data.data.id_session, enteredCode))
            }
        }

        const handleReset = () => {
            setInputs(["", "", "", ""])
            document.getElementById('input-number0').focus();
        }

        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='main-block5'>
                <Image src={urlicon} style={{width: '300px', height: '110px'}}/>
                <div className='container-numbers'>
                    <span className='text-for-label'>Введите четырехзначный код отправленный на номер</span>
                    <span className='number-phone' id='changePhone'>{phoneNumber}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                <span className='text-again'
                      style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? "Отправить повторно" : "Отправить повторно" + " " + `(${timeoutRepeat}` + " " + "сек" + ")"}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id={`input-number${index}`}
                                maxLength='1'
                                className='input-number'
                                type={isMobile ? "number" : "text"}
                                autoComplete="off"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
                <div onClick={() => handleReset()} style={{color: '#A7AFB7', cursor: 'pointer'}}>Очистить поля ввода
                </div>
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching}>Загружаем информацию...</Loader>
                </Dimmer>
            </div>
        )
    }

    const EnterDataReset = (props) => {
        const [errors, setErrors] = useState({});
        const fields = useSelector((state) => getAddressFields(state.address));
        const [mailValid, setMailValid] = useState(false);

        // const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

        const [formData, setFormData] = useState({
            phone: "",
            email: "",
        })


        const isValidEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        const validate = (fieldName, value) => {
            let fieldErrors = {};
            if (fieldName === 'email') {
                if (value.includes("@")) {
                    if (!isValidEmail(value)) {
                        fieldErrors = { [fieldName]: "Некорректная почта/логин"};
                    } else {
                        fieldErrors = { [fieldName]: ''};
                        setMailValid(true)
                    }
                } else {
                    fieldErrors = { [fieldName]: ''};
                }


            }
            if (fieldName === 'phone') {
                if (!value) {
                    fieldErrors = { [fieldName]: "Некорректный номер телефона"};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
        }
        const handleEmailChange = (e) => {
            validate('email', e.target.value);
            setFormData({
                ...formData,
                email: e.target.value,
            });
            //console.log(e.target.value)
        }

        //Обновление полей с телефонной маской
        // function updateField() {
        //     formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        //     dispatch(setPhone(fields[ADDRESS_PHONE]))
        //     console.log(formData.phone)
        //     dispatch(setAddressFields(fields));
        // }

        const handleChangeField = (fieldName, value) => {
            setFormData({
                ...formData,
                phone: value,
            });

        }


        //Заполнение полей с телефонной масокй при загрузке страницы
        // const mountDirectField = (data) => {
        //     data.forEach((element) => {
        //         fields[element.name] = element.value;
        //     });
        //     updateField();
        // }
        //
        // //Изменение полей при редактировании маски
        // const setDirectField = (name, data) => {
        //     if (name === 'countryPhoneCode') {
        //         fields['phone'] = '';
        //     }
        //     fields[name] = data;
        //     updateField();
        // }

        const handleResetPassword = () => {
            dispatch(setPhone(formData.phone))
            dispatch(setEmail(formData.email))
            if (formData.phone && formData.email) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (formData.phone) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (formData.email || formData.email.includes("@")) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (!formData.email || !formData.phone) {
                toastr.warning("Пожалуйста, заполните необходимые поля");
            }
        }


        return (
            <>
                <div className='main-block-reset-pass'>
                    <Image src={urlicon} style={{marginTop: '5%', display: "flex", justifyContent: "center", width: '300px', height: '110px'}}/>

                    <span className='text-for-label'>Забыли свой пароль? Укажите свой Email или номер телефона. На электронную почту вы получите одноразовый код, введите его и новый пароль на следующей странице</span>

                    <div className='input-container-reset-pass'>
                        <div className='registration-container'>
                            {/*<EmailSuggestions*/}
                            {/*    token={DADATA_API_KEY}*/}
                            {/*    inputProps={{*/}
                            {/*        className: 'registration-input',*/}
                            {/*        name: 'email',*/}
                            {/*        id: 'email',*/}
                            {/*        placeholder: "Логин или почта",*/}
                            {/*        required: true,*/}
                            {/*        onChange: handleChange*/}
                            {/*    }}*/}
                            {/*    defaultQuery={formData.email}*/}
                            {/*    count='4'*/}
                            {/*    filterLanguage={languageString}*/}
                            {/*    onChange={handleEmailChange}*/}
                            {/*/>*/}
                            <input
                                className='registration-input'
                                name='email'
                                id='email'
                                placeholder="Логин или почта"
                                value={formData.email}
                                onChange={(e) => handleEmailChange(e)}
                            />
                            {errors.email && <span className='edit-error'>{errors.email}</span>}
                        </div>


                        <div className='container-line'>
                            <div className="line-or"></div>
                            <div className="text-or">ИЛИ</div>
                            <div className="line-or"></div>
                        </div>

                        <div className='registration-container'>
                            <PhoneCountryMask
                                extraClass="registration-input-phone"
                                name="phone"
                                countries={filteredCountries}
                                country="RU"
                                loading={isFetchingCountries}
                                phone={formData.phone}
                                // phoneMask={phoneMask}
                                handleChangeField={handleChangeField}
                                nameCountry={ADDRESS_PHONE_COUNTRY}
                                namePhone={ADDRESS_PHONE}
                                fluid={true}
                                required
                            />
                            {errors.phone && <span className='edit-error'>{errors.phone}</span>}
                        </div>
                    </div>


                    <div className='container-button-registration'>
                        <Button className='button-confirm-registration' onClick={() => handleResetPassword()}>
                            Сбросить пароль
                        </Button>
                        <Button className='button-confirm-registration'
                                onClick={() => dispatch(setAuthScreen(AUTH_SCREEN_MAIN))}>
                            Назад
                        </Button>
                    </div>
                    <Dimmer active={isFetching} inverted>
                        <Loader active={isFetching}>Загружаем информацию...</Loader>
                    </Dimmer>

                </div>

            </>
        )
    }

    const NewPassword = (props) => {
        const [timeoutWait, setTimeoutWait] = useState(authMode.data.data.otp_wait_timeout);
        const [timeoutRepeat, setTimeoutRepeat] = useState(authMode.data.data.otp_get_timeout);

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [showPassword, setShowPassword] = useState(false)

        const [formData, setFormData] = useState({
            code: '',
            password: '',
            confirmPassword: '',
        })

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {

            let time = timeoutRepeat;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutRepeat(time);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
            let time = timeoutWait;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutWait(time);
                if (time === 0) {
                    clearInterval(intervalId);
                    toastr.error("Время сессии истекло, повторите попытку");
                    dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }, []);

        function handleSetShowPassword() {
            setShowPassword(!showPassword)
        }

        const handleChangeCode = (e) => {
            setFormData({ ...formData, code: e.target.value })
        }

        const handleChangePassword = (e) => {
            setFormData({ ...formData, password: e.target.value })
        }

        const handleChangeConfirmPassword = (e) => {
            setFormData({ ...formData, confirmPassword: e.target.value })
        }

        const confirmPassword = () => {
            if (formData.password && formData.code.length === 8) {
                dispatch(resetPasswordAuthByOTP(languageString, config.APP_CODE, data.phone === "" ? "" : data.phone, data.email === "" ? "" : data.email, authMode.data.data.id_session, formData.code, formData.password))
                if (mixpanel) {
                    mixpanel.track('Action. Reset password')
                }

            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, data.phone === "" ? "" : data.phone, data.email === "" ? "" : data.email));
        }

        return (
            <>
                <div className='main-block-reset-pass-confirm'>
                    <Image src={urlicon} style={{marginTop: '5%', display: "flex", justifyContent: "center", width: '300px', height: '110px'}}/>

                    <span className='text-for-label'>Введите одноразовый код и новый пароль</span>

                    <div className='enter-otp'>
                        <input
                            className='registration-input'
                            placeholder="Одноразовый код"
                            autoComplete="off"
                            value={formData.code}
                            maxLength='8'
                            onChange={(e) => handleChangeCode(e)}
                        />
                        <input
                            className='registration-input'
                            placeholder="Новый пароль"
                            value={formData.password}
                            autoComplete="off"
                            // type={showPassword ? "" +
                            //     "" : "password"}
                            onChange={(e) => handleChangePassword(e)}
                        />
                        {/*<Label*/}
                        {/*    icon={showPassword ? "eye" : "eye slash"}*/}
                        {/*    onClick={() => handleSetShowPassword()}*/}
                        {/*    className="user-login-toggle-show-password"*/}
                        {/*    id='eye-icon-id'*/}
                        {/*/>*/}
                        {/*<input*/}
                        {/*    className='registration-input'*/}
                        {/*    type={showPassword ? "text" : "password"}*/}
                        {/*    placeholder='Подтвердите пароль'*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={formData.confirmPassword}*/}
                        {/*    onChange={(e) => handleChangeConfirmPassword(e)}*/}
                        {/*/>*/}
                    </div>

                    <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                        <span className='text-again'
                              style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? "Отправить повторно" : "Отправить повторно" + " " + `(${timeoutRepeat}` + " " + "сек" + ")"}</span>
                    </button>

                    <div className='container-button-registration'>
                        <Button className='button-confirm-registration' onClick={() => confirmPassword()}>
                            Принять
                        </Button>
                    </div>

                    <Dimmer active={isFetching} inverted>
                        <Loader active={isFetching}>Загружаем информацию...</Loader>
                    </Dimmer>
                </div>

            </>

        )
    }

    const handleSendCode = () => {
        if (!phoneNumber && navigator.onLine) {
            toastr.warning("Пожалуйста, введите корректный номер телефона")
        } else {
            // dispatch(setAuthScreen(AUTH_SCREEN_AUTH_ENTER_OTP))
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
            if (mixpanel) {
                mixpanel.track('Action. Start Auth by OTP')
            }


        }
    }

    const onClose = () => {
        dispatch(closeAuth())
        dispatch(setAuthScreen('AUTH_SCREEN_MAIN'))
    }

    const filteredCountries = listPhoneCountries.filter(country => country.value === "RU");

    return (
        <Modal
            dimmer
            closeIcon
            open={showModal}
            onClose={() => onClose()}
            centered={true}
            closeOnDimmerClick
            className="user-login"
        >
            {authMode.mode === AUTH_SCREEN_AUTH_ENTER_OTP ? <ConfirmCode props={{phoneNumber, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_RESET_PWD_ENTER_DATA ? <EnterDataReset props={{data, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_RESET_PWD_ENTER_OTP ? <NewPassword props={{data, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_MAIN ?
                <>
                    <div className='main-block3'>
                        <Image src={urlicon} style={{marginTop: '5%', width: '300px', height: '110px'}}/>

                        <span className='text-for-label'>Для входа в приложение введите свой логин и пароль, или авторизуйтесь, используя номер телефона</span>

                        <PhoneCountryMask
                            handleChangeField={handleChangeField}
                            extraClass="shipping-field shipping-phone"
                            countries={filteredCountries}
                            // loading={isMobile ? "" : isFetchingCountries}
                            country="RU"
                            phone={phoneNumber}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={false}
                        />

                        <div className='container-button-confirm'>
                            <Link className="button-confirm-code"
                            >
                                <button
                                    className='text-for-link'
                                    onClick={() => handleSendCode()}
                                >Отправить код подтверждения
                                </button>
                            </Link>
                        </div>
                        <div className='container-line'>
                            <div className="line-or"></div>
                            <div className="text-or">ИЛИ</div>
                            <div className="line-or"></div>
                        </div>
                    </div>
                    <Modal.Content>
                        <Modal.Description>
                            {messageLoginError !== '' ? (
                                <Message
                                    negative
                                    icon="user times"
                                    header="Вы не авторизованы"
                                    content={cleanMessage(messageLoginError)}
                                />
                            ) : ''}
                            <Form error={state.emailError || state.passwordError} onSubmit={submitForm}
                                //loading={Boolean(isFetching)}
                            >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder="Логин или почта"
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                        error={state.emailError}
                                        //type="email"
                                        name="login"
                                        autoComplete="on"
                                        value={state.email}
                                        id='input-numbers'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        placeholder="Пароль"
                                        onChange={(e) => setState({...state, password: e.target.value})}
                                        error={state.passwordError}
                                        type={state.showPassword ? "text" : "password"}
                                        name="password"
                                        autoComplete="on"
                                        value={state.password}
                                        iconPosition="left"
                                        labelPosition="right"
                                        id="input-numbers">
                                        <Icon name="key"/>
                                        <input/>
                                        <Label
                                            icon={state.showPassword ? "eye" : "eye slash"}
                                            onClick={() => handleSetShowPassword(!state.showPassword)}
                                            className="user-login-toggle-show-password"
                                            id='eye-icon-id'
                                        />
                                    </Form.Input>
                                </Form.Field>
                                <div className="container-buttons-auth">
                                    <button
                                        disabled={state.email === '' || state.password === ''}
                                        className={state.email === '' || state.password === '' ? "login-form-submit-button-disabled" : "login-form-submit-button"}
                                        onClick={submitForm}

                                    >
                                        Войти
                                    </button>
                                    <span className='forgot-pass'
                                          onClick={() => navigator.onLine ? dispatch(setAuthScreen(AUTH_SCREEN_RESET_PWD_ENTER_DATA)) : toastr.warning(languageString === "ru" ? "Вы не в сети. Пожалуйста, подключитесь к Интернету" : 'You are offline. Please connect to the Internet')}>Забыли пароль?</span>
                                </div>
                                <br/>

                            </Form>
                        </Modal.Description>

                        <Dimmer active={isFetching} inverted>
                            <Loader active={isFetching}>Загружаем информацию...</Loader>
                        </Dimmer>

                    </Modal.Content>
                </> : ''}
        </Modal>


    );
}


