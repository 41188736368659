import React, {useState,useEffect} from 'react';
import './styles.css'
import {Rating, Dropdown} from "semantic-ui-react";
import {ReactComponent as ArrowIcon} from "../../icons/arrow-profile-icon.svg";
import DoubleLineChart from "../../components/GraphComponent";
import {useSelector} from "react-redux";
import {fetchUserData} from "../../components/UserLogin/actions";

export default function Profile(props) {
    const {navigate, token} = props;
    const userData = useSelector(state => state.userLogin.userData);
    const date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    const dateYear = new Date().getFullYear();
    const totalEarn = {
        ...userData.profiles.tasks.totals_earn
    }
    const totalYear = Object.values(totalEarn).reduce((accumulator, currentValue) => {
        accumulator.earned_sum += currentValue.earned_sum;
        accumulator.total_closed += currentValue.total_closed;
        accumulator.total_in_work += currentValue.total_in_work;
        return accumulator
    }, {
        earned_sum: 0,
        total_closed: 0,
        total_in_work: 0,
    })
    const totalEarnWithYear = {
        [dateYear + '-00']: totalYear,
        ...totalEarn
    }
    console.log(totalEarn)
    console.log(totalEarnWithYear, 'ss')
    console.log(userData)
    const [statDate, setStatDate] = useState(date);
    console.log(date)
    const dateOptions = [
        {
            key: 0,
            text: 'За этот месяц',
            value: date
        },
        {
            key: 1,
            text: 'Январь',
            value: dateYear + '-01'
        },
        {
            key: 2,
            text: 'Февраль',
            value: dateYear + '-02'
        },
        {
            key: 3,
            text: 'Март',
            value: dateYear + '-03'
        },
        {
            key: 4,
            text: 'Апрель',
            value: dateYear + '-04'
        },
        {
            key: 5,
            text: 'Май',
            value: dateYear + '-05'
        },
        {
            key: 6,
            text: 'Июнь',
            value: dateYear + '-06'
        },
        {
            key: 7,
            text: 'Июль',
            value: dateYear + '-07'
        },
        {
            key: 8,
            text: 'Август',
            value: dateYear + '-08'
        },
        {
            key: 9,
            text: 'Сентябрь',
            value: dateYear + '-09'
        },
        {
            key: 10,
            text: 'Октябрь',
            value: dateYear + '-10'
        },
        {
            key: 11,
            text: 'Ноябрь',
            value: dateYear + '-11'
        },
        {
            key: 12,
            text: 'Декабрь',
            value: dateYear + '-12'
        },
        {
            key: 13,
            text: 'За год',
            value: dateYear + '-00'
        }

    ];
    console.log(dateOptions)
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        const arrayTask = [];
        Object.values(userData.profiles.tasks.last_tasks).forEach((item, index) => {
            arrayTask.push(
                <div
                    className='profile-task'
                    style={{
                        backgroundColor: index % 2 === 0 ? '#F6FCFF' : '#EBF6FF',
                        borderRadius:  index === 0 ? '10px 10px 0 0' : '0 0 0 0' && index === 3 ? '0 0 10px 10px' : '0 0 0 0',
                    }}
                >
                    <div
                        className='profile-task-number'
                    >
                        №{item.id_order}
                    </div>
                    <div className='profile-task-text'>{item.product_name}</div>
                    <div className='profile-task-company'>{item.customer_name}</div>
                </div>
            )

        })
        setTasks(arrayTask);
    }, []);
            console.log(userData)
            //console.log(Object.values(userData.profiles.tasks.last_tasks))
    const handleChangeStatDate = (e, {value}) => {
        setStatDate(value);
    }
    return (
        <div className='profile-view'>
            <h1 className='profile-title-h1'>Личный кабинет</h1>
            <div className='profile-header'>
                <div className='profile-icon-container'>
                    <img src={userData.profiles.avatar} alt='Аватар' className='profile-icon'></img>
                    <div className='profile-rating-conainer'>
                        <div className='profile-rating'>
                            <div className='profile-rating-text'>от клиентов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.customer} size='large'></Rating>
                        </div>
                        <div className='profile-rating'>
                            <div className='profile-rating-text'>от специалистов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.admin} size='large'></Rating>
                        </div>
                    </div>
                </div>
                <div className='profile-nav-container'>
                    <div className='profile-name'>{userData.first_name + ' ' + userData.last_name}</div>
                    <div className='profile-divider'></div>
                    <nav className='profile-nav'>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'>Редактировать профиль</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item' onClick={() => navigate(`/service-payment`)}> 
                            <div className='profile-nav-item-text'>Оплата услуг</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item' onClick={() => navigate('/my-reviews')}>
                            <div className='profile-nav-item-text'>Мои отзывы</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text' onClick={() => navigate('/employment-calendar')}>Календарь</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='profile-tasks'>
                <h2 className='profile-title-h2'>Последние активные задачи</h2>
                {tasks.length > 0 ?
                    <div className='profile-tasks-container'>
                        {tasks}
                    </div>
                    :
                    <div style={{marginLeft: "5px"}}>Нет задач</div>
                }
            </div>
            <div className='profile-stats'>
                <h2 className='profile-title-h2'>Статистика</h2>
                <Dropdown
                    style={{width: '100%', marginBottom: '20px'}}
                    fluid
                    name='Выберите месяц'
                    value={statDate}
                    onChange={handleChangeStatDate}
                    search
                    selection
                    scrolling
                    noResultsMessage="Ничего не найдено"
                    options={dateOptions}
                />

                <div className='profile-stats-row'>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].total_in_work : 0}
                        </div>
                        <div className='profile-stats-text'>Задач в работе</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#EBF6FF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].total_closed : 0}
                        </div>
                        <div className='profile-stats-text'>Задач завершено</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].earned_sum : 0}₽
                        </div>
                        <div className='profile-stats-text'>{statDate===`${dateYear}-00` ? 'Поступления в этом году' : 'Поступления в этом месяце'}</div>
                    </div>
                </div>
            </div>
            <div className='profile-graph'>
                <h2 className='profile-title-h2'>Задачи за год</h2>
                <DoubleLineChart totalTasks={{...totalEarn}}/>
            </div>
        </div>
    )
}
